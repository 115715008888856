.Navbar_container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    font-size: 1.5em;
    width: 60%;
    padding: 10px;
    border-bottom: 1px solid darkslategray;
}
.Navbar_nav-buttons-container{
    color: white;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.Navbar_social-media-buttons-container{
    color: white;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: space-between;
}
.Navbar_nav-button{
    margin: 0px 20px;
}
.Navbar_social-icon{
    margin: 0px 5px;
}

a{
    text-decoration: none;
    color: white;
}
a:visited{
    color: white;
}
.Navbar_nav-button-selected{
    background-color: white;
    color: black;
    padding: 0px 5px;
}