.App {
  background-color: #182227;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'DotGothic16', sans-serif;
  white-space: pre-line;
}
