.Games_game-item-icon{
    border-radius: 100%;
    width: 125px;
    height: 125px;
}
.Games_game-item-container{
    background-color: white;
    border-radius: 10%;
    padding: 10px;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: large;
    margin: 0px 25px;
}
.Games_game-item-store-icon{
    width: 100px;
}
.Games_game-item-privacy-policy{
    color: black;
    font-size: small;
    text-decoration: none;
}
.Games_game-item-privacy-policy:visited{
    color: black;
}
.Games_container{
    display: flex;
    flex-direction: row;
}